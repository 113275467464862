import { ModalConfirmLogout } from '@smu-chile/pkg-unimarc-components/stories/organisms/Header/MyProfile/MyProfileContent/ModalConfirmLogout'
import { Item, logoutV2, useContentful } from '@smu-chile/pkg-unimarc-hooks'
import Cookies from 'js-cookie'
import { MODAL_LOGOUT_STRAWBERRY } from 'shared/constants'

export type LogoutModalProps = {
  onClose?: VoidFunction
  onLogoutClick?: VoidFunction
}

export const LogoutModal = ({ onClose, onLogoutClick }: LogoutModalProps) => {
  const contentful = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-modal-logout',
    options: {
      'sys.id': MODAL_LOGOUT_STRAWBERRY[0]
    },
    type: 'assets'
  })

  const [contentfulImage] = (contentful?.data?.['items'] ?? []) as Array<Item>

  const handleLogoutClick = () => {
    Cookies.remove('addToCart')
    if (onLogoutClick) onLogoutClick()
    logoutV2()
  }

  return (
    <ModalConfirmLogout
      imageModalLogout={contentfulImage?.fields?.file?.url}
      onClickCloseLogoutMobile={onClose}
      onClickLogout={handleLogoutClick}
    />
  )
}
