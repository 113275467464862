import { useRouter } from 'next/router'
import { LogoutModal } from './components/LogoutModal'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { useSession } from '@smu-chile/pkg-unimarc-hooks'

export const AuthWrapper = () => {
  const router = useRouter()
  const session = useSession()

  const showLoginMandatory = !session.isLoggedIn && !session.isLoading

  const showLoginModal = router.query.login === 'true' || showLoginMandatory
  const showLogoutModal = router.query.logout === 'true'

  const handleLoginModalClose = () => {
    if (session.isLoggedIn) {
      const { pathname, query } = router
      delete router.query.login
      router.replace({ pathname, query }, undefined, { shallow: true })
    } else {
      router.replace(process.env.NEXT_PUBLIC_HOMEURL || '/')
    }
  }

  const handleLogoutModalClose = () => {
    const { pathname, query } = router
    delete router.query.logout
    router.replace({ pathname, query }, undefined, { shallow: true })
  }

  return (
    <>
      {showLoginModal && (
        <LoginModalBrowse
          isOpen={true}
          onClose={handleLoginModalClose}
          router={router}
        />
      )}

      {showLogoutModal && (
        <LogoutModal
          onClose={handleLogoutModalClose}
          onLogoutClick={handleLogoutModalClose}
        />
      )}
    </>
  )
}
